import firebase from 'firebase/compat/app'
import "firebase/compat/auth"
import "firebase/compat/database"
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

var app = firebase.initializeApp({
    apiKey: "AIzaSyBkEFQmxnaKH84SMm0Dhth2Q4js2GuZldo",
    authDomain: "triflex-7e534.firebaseapp.com",
    projectId: "triflex-7e534",
    storageBucket: "triflex-7e534.appspot.com",
    messagingSenderId: "691421975669",
    appId: "1:691421975669:web:186b1fce023e014b7bd43c"
})
  export const storage = app.storage();

const auth = getAuth(app);
export const db = app.firestore();
export { auth };
