import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Container,
  AppBar,
  Toolbar,
  Box,
  Paper,
  Button,
  Tabs,
  Tab
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import logo from './triteklogo.png'; 
import { auth } from './FirebaseConfig';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import * as XLSX from 'xlsx';

const AdminReferrals = () => {
  const [customers, setCustomers] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const navigate = useNavigate()

  useEffect(() => {
    if (!auth.currentUser) {
      navigate('/login-referral-management');
    }
    fetchCustomers();
  }, [page, rowsPerPage, search]);

  const fetchCustomers = async () => {
    const response = await axios.get(`https://innovators.onrender.com/referrals`, {
      params: {
        page: page + 1,
        limit: parseInt(rowsPerPage, 10),
        search: search,
      },
    });
    setCustomers(response.data.customers);
    setTotal(response.data.total);
  };


  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(customers.map(customer => ({
      Name: customer.name,
      Email: customer.email,
      "Phone Number": customer.phone,
    })));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Customers');
    XLSX.writeFile(workbook, 'customers.xlsx');
  };


  return (
    <Container maxWidth="false" sx={{ mt: 4 }}>
      <AppBar position="static" sx={{ backgroundColor: '#fefefe' }}>
        <Toolbar style={{display:'flex', justifyContent:'space-between'}}>
          <img src={logo} alt="Logo" style={{ width: '150px', marginRight: '20px' }} />
          <div style={{display:'flex', gap:'1rem'}}>
            <Button variant="contained" color="primary" onClick={exportToExcel}>
              Export to Excel
            </Button>
            <IconButton onClick={() => {
              auth.signOut();
              navigate('/');
            }}>
              <LogoutIcon style={{color:'red'}}/>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Paper>
        <Typography variant="h4" color="black" align={'center'}>
          Referral Management
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
          <TextField
            label="Search Customers"
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            sx={{ width: '300px', bgcolor: 'white' }}
          />
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer) => (
                <TableRow key={customer.id}>
                  <TableCell>{customer.name}</TableCell>
                  <TableCell>{customer.email}</TableCell>
                  <TableCell>{customer.phone}</TableCell>
                  
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </Paper>
    </Container>
  );
};

export default AdminReferrals;
