import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, CircularProgress, Card, CardContent, CardActions } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import logo from './triteklogo.png'
import { db } from './FirebaseConfig';


const ReferralForm = () => {
  const [name, setName] = useState('');
  const [first, setFirst] = useState('');
  const [last, setLast] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false); // State for payment success


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    try {
      await db.collection('referrals').add({
        email,
        phone,
        name: first+' '+last
      });
      
      setPaymentSuccess(true); // Only set success if the operation succeeds
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
};

  const today = new Date();
  const nextPaymentDate = new Date(today.setDate(today.getDate() + 29));
  
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = nextPaymentDate.toLocaleDateString(undefined, options);

  if (paymentSuccess) {
    return (
      <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 4 }}>
         <img
        src={logo}
        style={{width:'200px'}}
        />
        <Card sx={{ p: 4, textAlign: 'center', backgroundColor: '#e0ffe0' }}>
          <CheckCircleIcon color="success" sx={{ fontSize: 60, mb: 2 }} />
          <CardContent>
            <Typography variant="h4" color="success.main" gutterBottom>
              Successful!
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
            Thank you! we will be in contact.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <img
        src={logo}
        style={{width:'200px'}}
        />
        <Typography variant="h4" gutterBottom>
        Welcome!
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
        Please fill out the form below to start your journey!
        </Typography>
      </Box>

      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <TextField
          fullWidth
          label="First Name"
          variant="outlined"
          value={first}
          onChange={(e) => setFirst(e.target.value)}
          required
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Last Name"
          variant="outlined"
          value={last}
          onChange={(e) => setLast(e.target.value)}
          required
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          sx={{ mb: 2 }}
        />

<TextField
          fullWidth
          label="Phone Number"
          variant="outlined"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
          sx={{ mb: 2 }}
        />
      
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
          fullWidth
        >
          {loading ? <CircularProgress size={24} /> : 'Submit'}
        </Button>
      </Box>
    </Container>
  );
};

export default ReferralForm