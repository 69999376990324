import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Container,
  AppBar,
  Toolbar,
  Box,
  Paper,
  Button,
  Tabs,
  Tab
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import logo from './triteklogo.png'; 
import { auth } from './FirebaseConfig';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import * as XLSX from 'xlsx';

const AdminTable = () => {
  const [customers, setCustomers] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [tabIndex, setTabIndex] = useState(0); // State to track active tab
  const navigate = useNavigate()

  useEffect(() => {
    if (!auth.currentUser) {
      navigate('/');
    }
    fetchCustomers();
  }, [page, rowsPerPage, search, tabIndex]);

  const fetchCustomers = async () => {
    const country = tabIndex === 0 ? '':tabIndex === 1 ? '-US':'-USA';
    const response = await axios.get(`https://innovators.onrender.com/customers${country.toLowerCase()}`, {
      params: {
        page: page + 1,
        limit: parseInt(rowsPerPage, 10),
        search: search,
      },
    });
    setCustomers(response.data.customers);
    setTotal(response.data.total);
  };

  const handleMenuClick = (event, customer) => {
    setAnchorEl(event.currentTarget);
    setSelectedCustomer(customer);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChange = async (status) => {
    try {
      await axios.put(`https://innovators.onrender.com/customers/${selectedCustomer.id}/status`, { status });
      fetchCustomers(); // Refresh the list
      handleMenuClose(); // Close menu
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(customers.map(customer => ({
      Name: customer.name,
      Email: customer.email,
      "Phone Number": customer.phone,
      "First Payment": `${customer.firstPaymentAmount} GBP`,
      "Date of First Payment": new Date(customer.firstPaymentDate).toLocaleDateString(),
      "Second Payment Date": new Date(customer.secondPaymentDate).toLocaleDateString(),
      Status: customer.secondPaymentStatus,
    })));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Customers');
    XLSX.writeFile(workbook, 'customers.xlsx');
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setPage(0); // Reset page when switching tabs
  };

  return (
    <Container maxWidth="false" sx={{ mt: 4 }}>
      <AppBar position="static" sx={{ backgroundColor: '#fefefe' }}>
        <Toolbar style={{display:'flex', justifyContent:'space-between'}}>
          <img src={logo} alt="Logo" style={{ width: '150px', marginRight: '20px' }} />
          <div style={{display:'flex', gap:'1rem'}}>
            <Button variant="contained" color="primary" onClick={exportToExcel}>
              Export to Excel
            </Button>
            <IconButton onClick={() => {
              auth.signOut();
              navigate('/');
            }}>
              <LogoutIcon style={{color:'red'}}/>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Paper>
        <Typography variant="h4" color="black" align={'center'}>
          Customers Management
        </Typography>
        
        {/* Tabs for UK and US */}
        <Tabs value={tabIndex} onChange={handleTabChange} centered>
          <Tab label="UK Customers" />
          <Tab label="CAD Customers" />
          <Tab label="US Customers" />
        </Tabs>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
          <TextField
            label="Search Customers"
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            sx={{ width: '300px', bgcolor: 'white' }}
          />
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>First Payment</TableCell>
                <TableCell>Date of First Payment</TableCell>
                <TableCell>Second Payment Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer) => (
                <TableRow key={customer.id}>
                  <TableCell>{customer.name}</TableCell>
                  <TableCell>{customer.firstPaymentAmount} {tabIndex === 0 ?'GBP':tabIndex === 1?'CAD':'USD'}</TableCell>
                  <TableCell>{new Date(customer.firstPaymentDate).toLocaleDateString()}</TableCell>
                  <TableCell>{new Date(customer.secondPaymentDate).toLocaleDateString()}</TableCell>
                  <TableCell>{customer.secondPaymentStatus}</TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleMenuClick(e, customer)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={() => handleStatusChange('succeeded')}>Mark payment as Completed</MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </Paper>
    </Container>
  );
};

export default AdminTable;
