import './App.css';
import AdminTable from './component/AdminTable';
import Login from './component/Login';
import StripeWrapper from './component/PaymentForm';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import StripeWrapperUs from './component/PaymentFormUs';
import StripeWrapperUSA from './component/PaymentFormUSA';
import ReferralForm from './component/Referralform';
import Login_ref from './component/Login_ref';
import AdminReferrals from './component/AdminReferrals';

function App() {
  return (
     <Router>
     <Routes>
       <Route path="/" element={<Login />} />
       <Route path="/login-referral-management" element={<Login_ref />} />
       <Route path="/admin" element={<AdminTable />} />
       <Route path="/admin-referrals" element={<AdminReferrals />} />
       <Route path="/pm-ba" element={<StripeWrapper />} />
       <Route path="/pm-ba-us" element={<StripeWrapperUs />} />
       <Route path="/pm-ba-usa" element={<StripeWrapperUSA />} />
       <Route path="/referral" element={<ReferralForm />} />
     </Routes>
   </Router>
  );
}

export default App;
